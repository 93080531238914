import { getCurrentUser } from 'aws-amplify/auth'
import { Form, Formik } from 'formik'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { createSearchParams, generatePath, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { BasicErrorComponent } from '@components/atoms/Error/Error'
import { PrimaryButton } from '@components/molecules/forms/buttons/PrimaryButton'
import { CannotClaimEmptyCard } from '@components/organisms/modals/CannotClaimEmptyCard/CannotClaimEmptyCard'
import { shareKeyPaths } from '@configs/urls'
import { datadogMessages } from '@constants/datadog'
import { shopResponseErrors } from '@constants/shopResponseErrors'
import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'
import { HttpError } from '@errors/httpError'
import { WithTranslateFormErrors } from '@hoc/WithTranslateErrors'
import { useHashFlowContext } from '@hooks/useHashFlowContext'
import { useUserContext } from '@hooks/useUserContext'
import { Flows, ReceiveInfo } from '@services/api.types'
import { Icons } from '@typeDeclarations/components/atoms/icons'
import { DivProps } from '@typeDeclarations/elements/div'
import { HashFlowActions } from '@typeDeclarations/hashFlowActions'
import { HashType } from '@typeDeclarations/hashType'
import { checkUserExists } from '@utils/checkUserExists'
import { receiveClaim } from '@utils/claimHelpers'

type Props = DivProps & {
  shareKey: string
  data: ReceiveInfo | undefined
}

export const SkipClaimForm: React.FC<Props> = ({ data, shareKey, ...props }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { dispatchHashFlows, hashFlows, setEmailDelayInfo } = useHashFlowContext()
  const {
    isLoggedIn,
    email: currentlyLoggedInUserEmail,
    logOut,
    setEmail,
    setIsLoggedIn,
  } = useUserContext()
  const [errorOccurred, setErrorOccurred] = useState(false)
  const [errorDescription, setErrorDescription] = useState<string>()
  const [cannotClaimEmptyCardModalOpen, setCannotClaimEmptyCardModalOpen] = useState(false)

  const receiveClaimForKnownUser = async (shareKey: string) => {
    const { username } = await getCurrentUser()
    await receiveClaim({
      username,
      email: currentlyLoggedInUserEmail,
      shareKey,
      unconfirmed_user: false,
      navigate,
      setEmailDelayInfo,
      setEmail,
      setIsLoggedIn,
    })
  }
  const receiveClaimForUnknownUser = async (shareKey: string) => {
    await receiveClaim({
      username: null,
      email: hashFlows[shareKey]?.prefillEmail ?? null,
      shareKey,
      unconfirmed_user: false,
      navigate,
      setEmailDelayInfo,
      setEmail,
      setIsLoggedIn,
    })
  }

  useEffect(() => {
    if (!data?.flow) return

    dispatchHashFlows({
      type: HashFlowActions.SetHashFlow,
      payload: {
        hash: shareKey,
        flow: data.flow,
        type: HashType.ShareKey,
        prefillEmail: data.prefill_email,
        skip_claim_step: data.skip_claim_step,
      },
    })

    datadogRum.setGlobalContextProperty('shareKey', {
      shareKey,
      flow: data.flow,
    })
  }, [data?.flow, shareKey, dispatchHashFlows])

  const initialValues = {}
  const cardEmpty = Number(data?.balance) === 0

  const redirectBasedOnIfUserExists = async (email: string) => {
    const userExists = email ? await checkUserExists(email) : false

    if (userExists) {
      navigate({
        pathname: generatePath(shareKeyPaths.account, { shareKey }),
        search: createSearchParams({ email }).toString(),
      })
      return
    }

    navigate(
      email
        ? {
            pathname: generatePath(shareKeyPaths.signUp, { shareKey }),
            search: createSearchParams({ email }).toString(),
          }
        : {
            pathname: generatePath(shareKeyPaths.account, { shareKey }),
          },
    )
  }

  const onSubmit = async () => {
    if (cardEmpty) {
      setCannotClaimEmptyCardModalOpen(true)
      return
    }

    if (!data?.flow) return
    datadogLogs.logger.info(datadogMessages.choiceCardClaimed)

    setErrorOccurred(false)

    try {
      switch (data.flow) {
        case Flows.ShopGuest:
          if (isLoggedIn) {
            await logOut()
          }
          await receiveClaimForUnknownUser(shareKey)
          return

        case Flows.ShopAccountMandatory:
          if (!isLoggedIn) {
            await redirectBasedOnIfUserExists('')
            return
          }
          await receiveClaimForKnownUser(shareKey)
          return

        case Flows.ShopAccountOptional:
          if (!isLoggedIn) {
            await redirectBasedOnIfUserExists('')
            return
          }

          await receiveClaimForKnownUser(shareKey)
          return
      }
    } catch (e) {
      setErrorOccurred(true)
      console.error('Something went wrong claiming card:', e)
      setErrorDescription(undefined)

      if (e instanceof HttpError && e.json !== null && typeof e.json === 'object') {
        const nonFieldError =
          'non_field_errors' in e.json && Array.isArray(e.json.non_field_errors)
            ? (e.json.non_field_errors[0] as string)
            : undefined
        if (nonFieldError && nonFieldError in shopResponseErrors)
          setErrorDescription('backend.' + nonFieldError)
      }
    }
  }

  return (
    <Root {...props}>
      <CannotClaimEmptyCard
        onClose={() => setCannotClaimEmptyCardModalOpen(false)}
        open={cannotClaimEmptyCardModalOpen}
      />
      <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit}>
        <WithTranslateFormErrors>
          <Form>
            {errorOccurred && (
              <BasicErrorComponent onClose={() => setErrorOccurred(false)}>
                {errorDescription ? t(errorDescription) : t('somethingWentWrong')}
              </BasicErrorComponent>
            )}
            <PrimaryButton disabled={!data?.flow} iconRight={Icons.ArrowRight} type="submit">
              {t('forms.actions.goToShop')}
            </PrimaryButton>
          </Form>
        </WithTranslateFormErrors>
      </Formik>
    </Root>
  )
}

const Root = styled.div``
