import { Navigate, Outlet, Route, createRoutesFromElements } from 'react-router-dom'

import type { RouteObject } from 'react-router-dom'

import { Global } from '@components/global'

import { NotFound } from '@components/organisms/404/404'
import { ErrorPage } from '@components/organisms/500/500'
import { PATHS, PATH_PARAMS, SUBPATH_PARAMS } from '@constants/paths'
import { eonSupportLinks } from '@constants/supportLinks'
import { CheckoutContextProvider } from '@context/checkout'
import { ProductsContextProvider } from '@context/products'
import { SettingsContextProvider } from '@context/settings'
import GeneralLayout from '@layouts/general/layout'
import ThinLayout from '@layouts/thin/layout'
import Activate from '@pages/activate/page'
import AccountCodeFlow from '@pages/card/[claim-code]/account/page'
import CartOverview from '@pages/card/[claim-code]/cart/overview/page'
import Product from '@pages/card/[claim-code]/catalogue/[product-id]/page'
import CataloguePage from '@pages/card/[claim-code]/catalogue/page'
import ProcessCode from '@pages/card/[claim-code]/process-code/page'
import SignUpCodeFlow from '@pages/card/[claim-code]/sign-up/page'
import Welcome from '@pages/card/[claim-code]/welcome/page'
import ClaimCodeSelect from '@pages/card/claim-code-select/page'
import Eon from '@pages/eon/page'
import IHaveACard from '@pages/i-have-a-card/page'
import IHaveAnEssentCard from '@pages/i-have-an-essent-card/page'
import Order from '@pages/order/[order-id]/page'
import Index from '@pages/page'
import Panels from '@pages/panels/page'
import AccountReceiveFlow from '@pages/receive/[share-key]/account/page'
import Claim from '@pages/receive/[share-key]/claim/page'
import Confetti from '@pages/receive/[share-key]/confetti/page'
import Details from '@pages/receive/[share-key]/details/page'
import PreparingChoiceCard from '@pages/receive/[share-key]/preparing-choice-card/page'
import SignUpReceiveFlow from '@pages/receive/[share-key]/sign-up/page'
import Refer from '@pages/refer/page'
import Enroll from '@pages/refer/enroll/[referral-code]/page'
import ConfigPreview from '@pages/config-preview/page'

const elements = (
  <Route
    element={
      <Global>
        <ProductsContextProvider>
          <SettingsContextProvider>
            <CheckoutContextProvider>
              <Outlet />
            </CheckoutContextProvider>
          </SettingsContextProvider>
        </ProductsContextProvider>
      </Global>
    }
    errorElement={
      <ThinLayout>
        <ErrorPage />
      </ThinLayout>
    }
  >
    <Route element={<ConfigPreview />} path={PATHS.configPreview} />
    <Route element={<ThinLayout brandBar={false} logo={false} noBranding />} path="*">
      <Route element={<NotFound />} path="*" />
    </Route>
    <Route element={<GeneralLayout />}>
      <Route element={<Index />} path="/" />
    </Route>
    <Route element={<ThinLayout />}>
      <Route element={<IHaveACard />} path={PATHS.iHaveACard} />
    </Route>
    <Route element={<ThinLayout />}>
      <Route element={<IHaveAnEssentCard />} path={PATHS.iHaveAnEssentCard} />
    </Route>
    <Route element={<GeneralLayout noBrandBar supportLinksSet={eonSupportLinks} />}>
      <Route element={<Eon />} path={PATHS.eon} />
    </Route>
    <Route element={<GeneralLayout />}>
      <Route path={PATHS.refer} element={<Refer />} />
      <Route path={PATHS.refer}>
        <Route path={PATHS.enroll}>
          <Route element={<Enroll />} path={PATH_PARAMS.referralCode} />
        </Route>
      </Route>
    </Route>
    <Route element={<GeneralLayout />}>
      <Route element={<Panels />} path={PATHS.panels} />
    </Route>
    <Route element={<ThinLayout brandBar={false} logo={false} noBranding />}>
      <Route path={PATHS.activate}>
        <Route element={<Activate />} path={PATH_PARAMS.shareKey} />
      </Route>
    </Route>
    <Route path={PATHS.receive}>
      <Route path={PATH_PARAMS.shareKey}>
        <Route element={<Navigate replace to={PATHS.confetti} />} path="" />
        <Route element={<GeneralLayout hideDefaultLogo />}>
          <Route element={<Confetti />} path={PATHS.confetti} />
        </Route>
        <Route element={<GeneralLayout />}>
          <Route element={<Claim />} path={PATHS.claim} />
        </Route>
        <Route element={<GeneralLayout />}>
          <Route element={<Details />} path={PATHS.claimSkip} />
        </Route>
        <Route element={<ThinLayout brandBar={false} logo={false} noBranding />}>
          <Route element={<PreparingChoiceCard />} path={PATHS.preparingChoiceCard} />
        </Route>
        <Route element={<ThinLayout />}>
          <Route element={<AccountReceiveFlow />} path={PATHS.account} />
          <Route element={<SignUpReceiveFlow />} path={PATHS.signUp} />
        </Route>
        <Route element={<Navigate replace to={PATHS.confetti} />} path="*" />
      </Route>
    </Route>
    <Route element={null}>
      <Route path={PATHS.order}>
        <Route element={<Order />} path={PATH_PARAMS.orderId} />
      </Route>
    </Route>
    <Route path={PATHS.card}>
      <Route element={<GeneralLayout />}>
        <Route element={<ClaimCodeSelect />} path={PATHS.claimCodeSelect} />
      </Route>
      <Route path={PATH_PARAMS.claimCode}>
        <Route element={<GeneralLayout />}>
          <Route element={<CartOverview />} path={PATHS.cart} />
        </Route>
        <Route element={null}>
          <Route element={<CartOverview />} path={PATHS.cart}>
            <Route element={null} path={SUBPATH_PARAMS.step}>
              <Route element={null} path={SUBPATH_PARAMS.substep}>
                <Route element={null} path={SUBPATH_PARAMS.orderId}></Route>
              </Route>
            </Route>
          </Route>
        </Route>
        <Route element={<Navigate replace to={PATHS.processCode} />} path="" />
        <Route element={<GeneralLayout />}>
          <Route element={<Welcome />} path={PATHS.welcome} />
        </Route>
        <Route element={<ProcessCode />} path={PATHS.processCode} />
        <Route element={<ThinLayout />}>
          <Route element={<AccountCodeFlow />} path={PATHS.account} />
          <Route element={<SignUpCodeFlow />} path={PATHS.signUp} />
        </Route>
        <Route element={<GeneralLayout />}>
          <Route path={PATHS.catalogue}>
            <Route element={<CataloguePage />} path="" />
            <Route path={PATH_PARAMS.productId}>
              <Route element={<Product />} path="" />
            </Route>
          </Route>
        </Route>
      </Route>
    </Route>
  </Route>
)

export const routes: RouteObject[] = createRoutesFromElements(elements)
