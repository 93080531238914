export const shop_order_survey_pk = import.meta.env.VITE_SHOP_ORDER_SURVEY_ID
export const shop_order_object_type = 'shop_order'

export const panel_survey_pk = import.meta.env.VITE_PANEL_SURVEY_ID
export const panel_object_type = 'panel'

const surveys = {
  shop_order: { survey_id: shop_order_survey_pk, object_type: shop_order_object_type },
  panel: { survey_id: panel_survey_pk, object_type: panel_object_type },
}

export type AvailableSurveys = keyof typeof surveys

export const getSurveyConfig = (type: AvailableSurveys) => ({
  survey_pk: surveys[type].survey_id,
  object_type: surveys[type].object_type,
})
